<template>
    <div class="OKRProject">
        <!-- 项目名称 -->
        <div class="name">
            <div class="nameLeft">{{ pageTitle }} <span class="icon iconfont" style="font-size: 18px;margin-left: 5px" :style="starStatus?'color: #FFD200':'color:#c8c8cc'" @click="_setStarStatus">&#xe692;</span></div>
            <div class="nameRight">
                <span class="icon c1" :style="currentIndex == 3 ? 'color: #5474FF' : ''" @click="swith(3)">进展动态</span>
                <span class="icon c1" :style="currentIndex == 2 ? 'color: #5474FF' : ''" @click="swith(2)">对齐视图</span>
                <span class="icon c1" @click="_refresh()">
                    <a-icon type="redo"/>
                </span>
                <span class="icon c1" @click="swith(0)" :class="{ active: currentIndex === 0 }">
                    <i class="iconfont">&#xe81c;</i>
                </span>
                <span class="icon c2" @click="swith(1)" :class="{ active: currentIndex === 1 }">
                    <i class="iconfont">&#xec6b;</i>
                </span>
            </div>
        </div>
        <!-- 卡片 -->
        <div v-if="currentIndex == 0">
            <div class="projectAdmin">
                <div class="container" :style="{overflowX:'scroll',width: containerWidth+'px',userSelect: 'none'}" @mousedown="stopDown ? _containerDown($event):''" @mousemove="stopDown ? _containerMove($event) : ''" @mouseup="stopDown ? _containerUp($event) : ''">
                    <draggable v-model="infoData" animation="300" style="white-space: nowrap" @sort="_waiDrag" @start="_onStart" @change="_onChange">
                        <div class="item" v-for="(item, index) in infoData" :key="index">
                            <div class="item-title flex" :style="{ background: item.BgColor }" @click.stop="_setData(item.Id, 1, '', index,[index])">
                                <div>
                                    <div style="font-size:10px;">{{item.alignment_info?item.alignment_info.CreateUserName:'+ 添加对齐'}}</div>
                                    <div class="title-text">{{ item.Name }}</div>
                                </div>
                                <a-popover placement="bottom">
                                    <template slot="content">
                                        <p style="padding-bottom: 5px; cursor: pointer" @click.stop="_setData(item.Id, 1, '', index,[index])">编辑</p>
                                        <a-popconfirm title="确定要删除吗?" ok-text="删除" cancel-text="取消" @confirm="kaConfirm(index)">
                                            <p style="cursor: pointer">删除</p>
                                        </a-popconfirm>
                                    </template>
                                    <a-icon type="ellipsis" style="cursor: pointer"/>
                                </a-popover>
                                <div class="schedule-div" :style="{width: item.Status + '%',background: item.SpeedColor,}"></div>
                            </div>

                            <div class="item-content">
                                <draggable v-model="item.Children" force-fallback="true" group="people" animation="300" delay="10" @start="_startDrag(index)" @add="_addDrag" @change="_changeDrag" @sort="_sortDrag" @end="_endDrag">
                                    <div class="tab flex" :class="startIndex == index + '-' + tabIndex ? 'start-active' : ''" v-for="(tab, tabIndex) in item.Children" :key="tabIndex" @click.stop="_setData(tab.Id, 2, item.Id,'',[index,tabIndex],tabIndex == item.Children.length - 1)">
                                        <img class="tab-img" :src="tab.Avatar" alt=""/>
                                        <div style="flex: 1">
                                            <div class="tab-title" style="word-wrap: break-word; word-break: break-all">
                                                <span style="color: #1890ff;font-family: cursive;font-size: 10px;" v-if="tab.TypeStr">{{tab.TypeStr+(tabIndex+1)}}</span>
                                                {{ tab.Name }}
                                            </div>
                                            <a-popover placement="bottomLeft">
                                                <template slot="content">
                                                    <div>
                                                        <div style="margin-bottom: 4px">当前进度</div>
                                                        <a-input v-model="tab.Status" :maxLength="3" :minLength="1" :id="'status-input-' + index + '-' + tabIndex" @keyup.enter="_setMsg(index, tabIndex, 'Speed')" @blur="_setMsg(index, tabIndex, 'Speed')" addon-after="%" style="width: 180px"/>
                                                    </div>
                                                    <div style="margin-top: 10px">状态</div>
                                                    <div class="status-list">
                                                        <div @click="_setMsg(index, tabIndex, 'Status', 1)">
                                                            <span style="border-color: #3699ff">
                                                                <i v-if="tab.ProjectStatus == 1" style="background: #3699ff"></i>
                                                            </span>
                                                            <p>正常</p>
                                                        </div>
                                                        <div @click="_setMsg(index, tabIndex, 'Status', 2)">
                                                            <span style="border-color: #f59336">
                                                                <i v-if="tab.ProjectStatus == 2" style="background: #f59336"></i>
                                                            </span>
                                                            <p>有风险</p>
                                                        </div>
                                                        <div @click="_setMsg(index, tabIndex, 'Status', 3)">
                                                            <span style="border-color: #f74e60">
                                                                <i v-if="tab.ProjectStatus == 3" style="background: #f74e60"></i></span>
                                                            <p>已延期</p>
                                                        </div>
                                                    </div>
                                                </template>
                                                <span
                                                        class="tab-item"
                                                        :style="{
                            background:
                              tab.ProjectStatus == 3
                                ? '#FFE2E5'
                                : tab.ProjectStatus == 2
                                ? '#FFF4DE'
                                : '#E1F0FF',
                            color:
                              tab.ProjectStatus == 3
                                ? '#F74E60'
                                : tab.ProjectStatus == 2
                                ? '#F59336'
                                : '#3699FF',
                          }"
                                                >{{
                            tab.ProjectStatus == 3
                              ? "已延期"
                              : tab.ProjectStatus == 2
                              ? "有风险"
                              : "正常"
                          }}{{ tab.Status || 0 }}%</span
                                                >
                                            </a-popover>
                                        </div>
                                        <a-popconfirm title="你确定要删除吗？" ok-text="确定" cancel-text="取消" @confirm="_delete($event, index, tabIndex)">
                                            <i class="iconfont tab-del" @click.stop>&#xe60a;</i>
                                        </a-popconfirm>
                                    </div>
                                </draggable>
                                <div style="text-align: center">
                                    <input
                                            placeholder=" +添加Key Result 按Enter"
                                            class="add-btn"
                                            @keyup.enter="_addRow($event, index)"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="item">
                            <a-input
                                    placeholder="+ 添加Objective 按Enter"
                                    class="add-btn"
                                    v-model="pageAddText"
                                    @keyup.enter="_addRow($event)"
                            ></a-input>
                        </div>
                    </draggable>
                </div>
                <!--评论-->
                <div class="page-title comment-title" v-if="currentIndex != 2">
                    <span class="title-text">评论</span>
                </div>
                <div class="comment-list" v-if="currentIndex != 2">
                    <ul>
                        <li class="commit-list-item" v-for="(item, index) in commentList" :key="index" style="display: block">
                            <div class="item-content">{{item.Content}}</div>
                            <div class="operation-msg">
                                <img :src="item.Avatar" alt="">
                                <div>
                                    <!-- {{item.CreateUserName}} {{item.TimeText}} -->
                                    {{item.SubTitle}}
                                    <a-popconfirm title="你确定要删除该评论?" ok-text="删除" cancel-text="取消" @confirm="confirmEVA($event, index)" @cancel="cancel">
                                        <span class="deleteComment">
                                            <i class="iconfont">&#xe60a;</i>
                                        </span>
                                    </a-popconfirm>
                                </div>
                            </div>
                        </li>

                        <li>
                            <div class="comment-msg">
                                <a-textarea
                                        class="textarea"
                                        v-model="textarea"
                                        placeholder="添加评论"
                                        @keyup.entee="_save"
                                ></a-textarea>
                            </div>
                        </li>
                    </ul>
                    <div style="text-align: right; margin-top: 10px; margin-bottom: 20px">
                        <a-button type="primary" @click="_save">评论</a-button>
                    </div>
                </div>
            </div>
        </div>
        <!-- 列表 -->
        <div style="width: 100%; height: 100%" v-if="currentIndex == 1">
            <!-- 项目 -->
            <draggable v-model="keyList" @sort="_listDrag">
                <div class="progess" v-for="(item, table) in keyList" :key="table">
                    <div class="tabTop">
                        <div class="tabLeft"></div>
                        <div class="tabRight">
                            <span class="tabList">进度</span>
                            <span class="tabList">权重</span>
                            <span class="tabList">总分</span>
                        </div>
                    </div>
                    <div class="top">
                        <div class="targetLeft">
                            <img v-if="item.Avatar" class="img" :src="item.Avatar" alt=""/>
                            <img v-else class="img" :src="userInfo.Avatar" alt=""/>
                            <div class="deviation">
                                <a-input
                                        class="int"
                                        v-model="item.Name"
                                        style="word-break: break-all; width: 100%"
                                        @keyup.enter="_saveO($event, table, indexone)"
                                ></a-input>
                            </div>
                        </div>
                        <div class="targetRight">
                            <div class="operation-list flex">
                                <div>
                                    <div>
                                        <a-popover placement="bottomLeft">
                                            <template slot="content">
                                                <div>
                                                    <div style="margin-bottom: 4px">
                                                        当前进度 {{ item.Status }}%
                                                    </div>
                                                </div>
                                                <div style="margin-top: 10px">状态</div>
                                                <div class="status-list">
                                                    <div @click="_setMsg(table, '', 'Status', 1)">
                            <span style="border-color: #3699ff"
                            ><i
                                    v-if="item.ProjectStatus == 1"
                                    style="background: #3699ff"
                            ></i
                            ></span>
                                                        <p>正常</p>
                                                    </div>
                                                    <div @click="_setMsg(table, '', 'Status', 2)">
                            <span style="border-color: #f59336"
                            ><i
                                    v-if="item.ProjectStatus == 2"
                                    style="background: #f59336"
                            ></i
                            ></span>
                                                        <p>有风险</p>
                                                    </div>
                                                    <div @click="_setMsg(table, '', 'Status', 3)">
                            <span style="border-color: #f74e60"
                            ><i
                                    v-if="item.ProjectStatus == 3"
                                    style="background: #f74e60"
                            ></i
                            ></span>
                                                        <p>已延期</p>
                                                    </div>
                                                </div>
                                            </template>
                                            <a-button class="btn" type="link">
                                                <!-- <i
                                                class="iconfont"
                                                :style="{
                                                  color:
                                                    item.ProjectStatus == 1
                                                      ? '#3699FF'
                                                      : item.ProjectStatus == 2
                                                      ? '#F59336'
                                                      : '#F74E60',
                                                }"
                                                >&#xe653;</i
                                              > -->
                                                <a-progress
                                                        type="circle"
                                                        :width="20"
                                                        :strokeWidth="15"
                                                        :percent="item.Status"
                                                        :showInfo="false"
                                                >
                                                    <!-- <template #format="circle">
                                                      <span style="color: red">{{ percent }}</span>
                                                    </template> -->
                                                </a-progress>
                                            </a-button>
                                        </a-popover>
                                    </div>
                                </div>
                                <!-- 进度 -->
                                <div class="item" style="width: 50px; text-align: left;">
                  <span style="font-size: 15px; color: #3f4254"
                  >{{ item.Status }}%</span>
                                </div>
                                <!-- 权重 -->
                                <div class="item"
                                     style="width: 50px; text-align: center;"
                                     @click.stop="_setDom(table + '-' + 1000000 + 'a')"
                                >
                  <span style="font-size: 15px; color: #3f4254"
                  >{{ item.ProjectWeight }}%</span
                  >
                                </div>
                                <!-- 总分 -->
                                <div class="item"
                                     style="width: 50px; text-align: center;"
                                     @click.stop="_setDom(table + '-' + 1000000 + 'b')"
                                >
                                    <a-input
                                            v-if="showIn == table + '-' + 1000000 + 'b'"
                                            :maxLength="3"
                                            :minLength="1"
                                            :min="0"
                                            :max="1"
                                            style="width: 100%; text-align: center"
                                            type="text"
                                            v-model="item.ProjectScore"
                                            @keyup.enter="_scoreData($event, table, 1000000)"
                                    />
                                    <span v-else style="font-size: 15px; color: #3f4254">{{
                    item.ProjectScore
                  }}</span>
                                </div>

                                <div class="c5" style="width: 50px">
                                    <div>
                                        <a-popover>
                                            <template slot="content">
                                                <div
                                                        @click.stop="_setData(item.Id, 1, '', table,[table],table == keyList.length - 1)"
                                                        style="padding: 2px; cursor: pointer"
                                                >
                                                    编辑
                                                </div>
                                                <div
                                                        @click="_OKRdelete($event, table)"
                                                        style="padding: 2px; cursor: pointer"
                                                >
                                                    删除
                                                </div>
                                            </template>
                                            <i class="iconfont" style="color: #3f4254">&#xe683;</i>
                                        </a-popover>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <draggable
                            v-model="item.Children"
                            @start="_start(table)"
                            @add="_addListDrag"
                            @change="_liDrag"
                            @sort="_sort"
                    >
                        <div class="center" v-for="(itemone, indexone) in item.Children" :key="indexone">
                            <div class="krLeft">
                                <img class="img" :src="itemone.Avatar || userInfo.Avatar" alt=""/>
                                <div class="KRdeviation">
                                    <a-input
                                            class="int"
                                            v-model="itemone.Name"
                                            placeholder="请输入一条Key result"
                                            @keyup.enter="_saveKR($event, table, indexone)"
                                            @blur="_saveKR($event, table, indexone)"
                                            :id="'int_'+table+'_'+indexone"
                                            style="word-break: break-all; width: 100%"
                                    ></a-input>
                                </div>
                            </div>
                            <div class="krRight">
                                <div class="operation-list flex">
                                    <div>
                                        <div>
                                            <a-popover placement="bottomLeft">
                                                <template slot="content">
                                                    <div>
                                                        <div style="margin-bottom: 4px">当前进度</div>
                                                        <a-input
                                                                id="Ainput"
                                                                v-model="itemone.Status"
                                                                :maxLength="3"
                                                                :minLength="1"
                                                                :id="'status-input-' + table + '-' + indexone"
                                                                @keyup.enter="_setMsg(table, indexone, 'Speed')"
                                                                @mouseleave="_leaveMsg(table, indexone, 'Speed')"
                                                                @mouseover="_overMsg(table, indexone, 'Speed')"
                                                                ref="status"
                                                                addon-after="%"
                                                                style="width: 180px"
                                                        />
                                                    </div>
                                                    <div style="margin-top: 10px">状态</div>
                                                    <div class="status-list">
                                                        <div @click="_setMsg(table, indexone, 'Status', 1)">
                              <span style="border-color: #3699ff"
                              ><i
                                      v-if="itemone.ProjectStatus == 1"
                                      style="background: #3699ff"
                              ></i
                              ></span>
                                                            <p>正常</p>
                                                        </div>
                                                        <div @click="_setMsg(table, indexone, 'Status', 2)">
                              <span style="border-color: #f59336"
                              ><i
                                      v-if="itemone.ProjectStatus == 2"
                                      style="background: #f59336"
                              ></i
                              ></span>
                                                            <p>有风险</p>
                                                        </div>
                                                        <div @click="_setMsg(table, indexone, 'Status', 3)">
                              <span style="border-color: #f74e60"
                              ><i
                                      v-if="itemone.ProjectStatus == 3"
                                      style="background: #f74e60"
                              ></i
                              ></span>
                                                            <p>已延期</p>
                                                        </div>
                                                    </div>
                                                </template>
                                                <a-button class="btn" type="link">
                                                    <!-- <i
                                                  class="iconfont"
                                                  :style="{
                                                    color:
                                                      itemone.ProjectStatus == 1
                                                        ? '#3699FF'
                                                        : itemone.ProjectStatus == 2
                                                        ? '#F59336'
                                                        : '#F74E60',
                                                  }"
                                                  >&#xe653;</i
                                                > -->
                                                    <a-progress
                                                            type="circle"
                                                            :width="20"
                                                            :strokeWidth="15"
                                                            :percent="itemone.Status"
                                                            :showInfo="false"
                                                            :strokeColor="getProgressColor(table,indexone)"
                                                    />
                                                </a-button>
                                            </a-popover>
                                        </div>
                                    </div>
                                    <!--子项进度-->
                                    <div class="item" style="width: 50px; text-align: left"
                                         @click.stop="_setDom(table + '-' + indexone + 'c')">
                                        <a-input
                                                v-if="showIn == table + '-' + indexone + 'c'"
                                                :maxLength="3"
                                                :minLength="1"
                                                style="width: 100%; text-align: center"
                                                type="text"
                                                v-model="itemone.Status"
                                                :value="itemone.Status"
                                                @keyup.enter="_setProgress(table, indexone,'Speed')"
                                        ></a-input>
                                        <span v-else style="font-size: 15px; color: #3f4254"
                                        >{{ itemone.Status }}%</span
                                        >
                                    </div>

                                    <!--子项权重-->
                                    <div
                                            class="item"
                                            style="width: 50px; text-align: center"
                                            @click.stop="_setDom(table + '-' + indexone + 'a')"
                                    >
                                        <a-input
                                                v-if="showIn == table + '-' + indexone + 'a'"
                                                :disabled="
                        indexone == item.Children.length - 1 ? true : false
                      "
                                                :maxLength="3"
                                                :minLength="1"
                                                style="width: 100%; text-align: center"
                                                type="text"
                                                v-model="itemone.ProjectWeight"
                                                :value="itemone.ProjectWeight"
                                                @keyup.enter="_updata($event, table, indexone)"
                                        ></a-input>
                                        <span v-else style="font-size: 15px; color: #3f4254"
                                        >{{ itemone.ProjectWeight }}%</span
                                        >
                                    </div>

                                    <!--子项总分-->
                                    <div
                                            class="item"
                                            style="width: 50px; text-align: center"
                                            @click.stop="_setDom(table + '-' + indexone + 'b')"
                                    >
                                        <a-input
                                                v-if="showIn == table + '-' + indexone + 'b'"
                                                :min="0"
                                                :max="1"
                                                style="width: 100%; text-align: center"
                                                type="text"
                                                v-model="itemone.ProjectScore"
                                                @keyup.enter="_scoreData($event, table, indexone)"
                                        ></a-input>
                                        <span v-else style="font-size: 15px; color: #3f4254">{{
                      itemone.ProjectScore
                    }}</span>
                                    </div>

                                    <div class="c5" style="width: 50px; text-align: right">
                                        <div>
                                            <a-popover>
                                                <template slot="content">
                                                    <div
                                                            @click.stop="_setData(itemone.Id, 2,item.Id,'',[table,indexone],indexone == item.Children.length -1)"
                                                            style="padding: 2px; cursor: pointer"
                                                    >
                                                        编辑
                                                    </div>
                                                    <div
                                                            @click="_delete($event, table, indexone)"
                                                            style="padding: 2px; cursor: pointer"
                                                    >
                                                        删除
                                                    </div>
                                                </template>
                                                <i class="iconfont" style="color: #3f4254">&#xe683;</i>
                                            </a-popover>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </draggable>
                    <div class="bottom">
                        <a-icon class="addIcon" type="plus"/>
                        <a-button @click="addResult(table)" type="link" class="addResult">
                            添加key result
                        </a-button>
                    </div>
                </div>
            </draggable>
            <!-- objective -->
            <!-- 添加1111 -->
            <div class="additional">
                <a-input
                        v-model.trim="pageAddText"
                        placeholder="添加Objective 按Enter"
                        @keyup.enter="_addRow($event)"
                ></a-input>
            </div>
            <!--评论-->
            <div class="page-title comment-title" v-if="currentIndex != 2">
                <span class="title-text">评论</span>
            </div>
            <div class="comment-list" v-if="currentIndex != 2">
                <ul>
                    <li class="commit-list-item" v-for="(item, index) in commentList" :key="index" style="display: block">
                        <div class="item-content">{{item.Content}}</div>
                        <div class="operation-msg">
                            <img :src="item.Avatar" alt="">
                            <div>
                                {{item.CreateUserName}} {{item.TimeText}}
                                <a-popconfirm title="你确定要删除该评论?" ok-text="删除" cancel-text="取消" @confirm="confirmEVA($event, index)" @cancel="cancel">
                                        <span class="deleteComment">
                                            <i class="iconfont">&#xe60a;</i>
                                        </span>
                                </a-popconfirm>
                            </div>
                        </div>
                    </li>

                    <li>
                        <div class="comment-msg">
                            <a-textarea
                                    class="textarea"
                                    v-model="textarea"
                                    placeholder="添加评论"
                                    @keyup.entee="_save"
                            ></a-textarea>
                        </div>
                    </li>
                </ul>
                <div style="text-align: right; margin-top: 10px; margin-bottom: 20px">
                    <a-button type="primary" @click="_save">评论</a-button>
                </div>
            </div>
        </div>
        <!--对齐视图-->
        <div v-if="currentIndex == 2" class="center-view">
            <div id="center-view"></div>
        </div>
        <!--进展动态-->
        <div class="dynamic-div" v-if="currentIndex == 3">
            <dynamic></dynamic>
        </div>
        <a-drawer title="编辑" width="600" placement="right" :closable="false" @close="_cancelData()" :bodyStyle="{padding: 0}" :visible="detailVisible">
            <div class="OKRDetails" style="padding: 20px">
                <div class="content">
                    <input class="int" type="text" placeholder="请添加标题" v-model="alertTitle"/>
                    <ul class="flex total-div">
                        <li>
                            <div class="label-name">进度</div>
                            <div class="label-num">
                                <a-input-number addon-after="%" :max="100" v-model="schedule" :disabled="setType == 1 ? true : false"></a-input-number>
                            </div>
                        </li>
                        <li>
                            <div class="label-name">权重1</div>
                            <div class="label-num">
                                <a-input-number
                                        addon-after="%"
                                        v-model="weight"
                                        :max="100"
                                        :disabled="setType == 1 || islast? true : false"
                                ></a-input-number>
                            </div>
                        </li>
                        <li style="width: 50px">
                            <div class="label-name">总分</div>
                            <div class="label-num">
                                <a-input-number
                                        v-model="total"
                                        :min="0"
                                        :max="1"
                                        :disabled="setType == 1 ? true : false"
                                ></a-input-number>
                            </div>
                        </li>
                    </ul>
                    <div class="founder">
                        <div class="information">创建人</div>
                        <div class="theName">{{ createPer }}</div>
                    </div>
                    <div class="starStop">
                        <div class="lintTime">起止时间</div>
                        <div class="lintDate">
                            <a-range-picker v-model="startDate" @change="_customDate"/>
                        </div>
                    </div>
                    <div class="projectPersonnel">
                        <div class="responsiblePerson">
                            <div class="nickName">主要负责人(负责人只能添加一个)</div>
                            <div class="headPortrait">
                                <div class="itemPortrait" v-if="cutReadPerson">
                                    <img
                                            class="img"
                                            :src="cutReadPerson[0].icon || cutReadPerson[0].Avatar"
                                            alt=""
                                    />
                                    <div>
                                        {{ cutReadPerson[0].title || cutReadPerson[0].UserName }}
                                    </div>
                                </div>
                                <div class="itemPortrait">
                                    <i
                                            class="iconfont"
                                            style="position: relative; top: -10px; font-size: 37px"
                                    >&#xe676;</i
                                    >
                                    <div></div>
                                    <person
                                            class="person"
                                            v-bind="personObj1"
                                            @getData="cutReadChangePerson"
                                    ></person>
                                </div>
                            </div>
                        </div>
                        <div class="responsiblePerson" style="margin-top: 15px">
                            <div class="nickName">参与人员</div>
                            <div class="headPortrait">
                                <div
                                        class="itemPortrait"
                                        v-if="participate"
                                        v-for="(item, index) in participate"
                                        :key="index"
                                        style="word-wrap: break-word; word-break: break-all"
                                >
                                    <img class="img" :src="item.icon || item.Avatar" alt=""/>
                                    <div>{{ item.title || item.UserName }}</div>
                                    <i
                                            class="iconfont icon-bohui del-icon"
                                            @click="_delPerson(index)"
                                    ></i>
                                </div>
                                <div class="itemPortrait">
                                    <i
                                            class="iconfont"
                                            style="position: relative; top: -10px; font-size: 37px"
                                    >&#xe676;</i
                                    >
                                    <div></div>
                                    <person
                                            class="person"
                                            v-bind="personObj2"
                                            @getData="participatePerson"
                                    ></person>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="center" v-if="setType == 1 && isAllowAlignment">
                        <div class="center-title">对齐</div>
                        <div class="center-list">
                            <div class="list-item" v-for="(item, index) in OBindData">
                                <div class="list-item-l">
                                    <img class="item-avatar" :src="item.Avatar" alt=""/>
                                </div>
                                <div class="list-item-r">
                                    <span class="item-name" :title="item.Title"><span class="tags" :style="item.Type==1?'background-color: rgb(24 144 255 / 11%);':''" v-if="item.TypeStr">{{item.TypeStr}}</span>{{ item.Title }}</span>
                                    <div class="item-txt">{{ item.SubTitle }}</div>
                                </div>
                                <i class="iconfont tab-del" @click="_delOBind(index)">&#xe60a;</i>
                            </div>
                        </div>
                        <a-button class="add-obj" style="height: 36px;" @click="_relevance()"><a-icon type="plus"/>关联Objective</a-button>
                    </div>


                </div>
            </div>
            <div class="model-comment">
                <div class="model-comment-title">评论</div>
                <div class="model-comment-content">
                    <div class="list-item" v-for="(item,index) in detailCommentList">
                        <div>
                            <img class="comment-avatar" :src="item.Avatar" alt="">
                            <span class="comment-name">{{item.CreateUserName}}</span>
                            <span class="comment-time">{{item.TimeText}}</span>
                            <a-popconfirm title="你确定要删除该评论?" ok-text="删除" cancel-text="取消" @confirm="_delCommentRow(item.Id,index)">
                                <span class="deleteComment" style="margin-left: 10px;" v-if="item.CreateUserId==userInfo.uid"><i class="iconfont">&#xe60a;</i></span>
                            </a-popconfirm>
                        </div>
                        <div class="comment-text">{{item.Content}}</div>
                    </div>

                    <div class="add-comment-model">
                        <textarea v-model="addCommentText" placeholder="添加评论"></textarea>
                        <div style="text-align: right;">
                            <a-button @click="addCommentText = ''">取消</a-button>
                            <a-button type="primary" style="margin-left: 20px" @click="_addCommentSave">确定</a-button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="alert-footer">
                <a-button style="margin-right: 25px" @click="_cancelData()">取消</a-button>
                <a-button type="primary" @click="_sub()">确定</a-button>
            </div>
        </a-drawer>
        <!--关联-->
        <a-drawer title="对齐" width="500" placement="right" :closable="false" @close="_relevanceCencel" :visible="relevanceVisible">
            <div class="relevanceContent">
                <div>
                    <a-input-search
                            v-model="searchTackText"
                            style="width: 300px"
                            placeholder="搜索员工"
                            @keyup.enter="_bingTask()"
                            enter-button
                            @search="_bingTask()"
                    ></a-input-search>
                    <!--<person class="person" v-bind="personObj3" @getData="cutReadChangePerson"></person>-->
                </div>

                <div class="center-list">
                    <div
                            class="list-item"
                            style="cursor: pointer; margin-top: 15px"
                            v-for="(item, index) in relevanceList"
                            @click="_addRelevance(index)"
                    >
                        <!-- <div>
                            <img class="item-avatar" :src="item.Avatar" alt=""/>
                            <span class="item-name"><span class="tags" :style="item.Type==1?'background-color: rgb(24 144 255 / 11%);':''" v-if="item.TypeStr">{{item.TypeStr}}</span>{{ item.Title }}</span>
                        </div>
                        <div class="item-txt">{{ item.SubTitle }}</div>
                        <i
                                v-if="relevanceSelect.indexOf(index) == -1"
                                class="iconfont icon-yuanxing icon-radio"
                        ></i>
                        <i
                                v-else
                                class="iconfont icon-tongyi icon-radio"
                                style="color: #1890ff"
                        ></i> -->

                        <div class="list-item-l">
                            <img class="item-avatar" :src="item.Avatar" alt=""/>
                        </div>
                        <div class="list-item-r">
                            <span class="item-name" :title="item.Title"><span class="tags" v-if="item.TypeStr">{{item.TypeStr}}</span>{{ item.Title }}</span>
                            <div class="item-txt">{{ item.SubTitle }}</div>
                        </div>
                        <i v-if="relevanceSelect.indexOf(index) == -1" class="iconfont icon-yuanxing icon-radio"></i>
                        <i v-else class="iconfont icon-tongyi icon-radio" style="color: #1890ff"></i>
                        <!-- <div class="schedule"></div> -->
                    </div>
                </div>
            </div>

            <div class="alert-footer">
                <a-button style="margin-right: 25px" @click="_relevanceCencel"
                >取消
                </a-button
                >
                <a-button type="primary" @click="_saveRelevance">确定</a-button>
            </div>
        </a-drawer>
        <!--视图详情-->
        <a-drawer
                title="O详情"
                width="700"
                placement="right"
                :closable="false"
                @close="_centerViewCencel"
                :visible="centerViewVisible"
        >
            <div class="center-view-draw">
                <div class="conter-view-content">
                    <div class="progess">
                        <div v-if="centerViewList">
                            <div class="top">
                                <div class="targetLeft">
                                    <img
                                            class="img"
                                            :src="centerViewList.Avatar || userInfo.Avatar"
                                            alt=""
                                    />
                                    <span>{{ centerViewList.Name }}</span>
                                </div>
                                <div class="targetRight">
                                    <div class="operation-list flex">
                                        <div>
                                            <div>
                                                <a-progress
                                                        type="circle"
                                                        :width="20"
                                                        :strokeWidth="15"
                                                        :percent="centerViewList.Status"
                                                        :showInfo="false"
                                                />
                                            </div>
                                        </div>
                                        <div
                                                class="item"
                                                style="width: 70px; text-align: left; position: relative"
                                        >
                      <span
                              style="
                          font-size: 15px;
                          color: #3f4254;
                          margin-left: 5px;
                        "
                      >{{ centerViewList.Status }}%</span
                      >
                                            <span
                                                    style="
                          position: absolute;
                          top: -25px;
                          left: 0;
                          font-size: 14px;
                          color: #8f959e;
                          white-space: nowrap;
                        "
                                            >进度</span
                                            >
                                        </div>
                                        <div
                                                class="item"
                                                style="
                        width: 70px;
                        text-align: center;
                        position: relative;
                      "
                                        >
                      <span style="font-size: 15px; color: #3f4254"
                      >{{ centerViewList.ProjectWeight }}%</span
                      >
                                            <span
                                                    style="
                          position: absolute;
                          top: -25px;
                          left: 50%;
                          transform: translateX(-50%);
                          font-size: 14px;
                          color: #8f959e;
                          white-space: nowrap;
                        "
                                            >权重</span
                                            >
                                        </div>
                                        <div
                                                class="item"
                                                style="
                        width: 70px;
                        text-align: center;
                        position: relative;
                      "
                                        >
                      <span style="font-size: 15px; color: #3f4254">{{
                        centerViewList.ProjectScore
                      }}</span>
                                            <span
                                                    style="
                          position: absolute;
                          top: -25px;
                          left: 50%;
                          transform: translateX(-50%);
                          font-size: 14px;
                          color: #8f959e;
                          white-space: nowrap;
                        "
                                            >总分</span
                                            >
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div
                                    class="top"
                                    style="margin-left: 40px; border-top: 1px solid #dee0e3"
                                    v-for="tab in centerViewList.Children"
                            >
                                <div class="targetLeft">
                                    <img
                                            class="img"
                                            :src="tab.Avatar || userInfo.Avatar"
                                            alt=""
                                    />
                                    <span>{{ tab.Name }}</span>
                                </div>
                                <div class="targetRight">
                                    <div class="operation-list flex">
                                        <div>
                                            <div>
                                                <a-progress
                                                        type="circle"
                                                        :width="20"
                                                        :strokeWidth="15"
                                                        :percent="tab.Status"
                                                        :showInfo="false"
                                                />
                                            </div>
                                        </div>
                                        <div
                                                class="item"
                                                style="width: 70px; text-align: left; position: relative"
                                        >
                      <span
                              style="
                          font-size: 15px;
                          color: #3f4254;
                          margin-left: 8px;
                        "
                      >{{ tab.Status }}%</span
                      >
                                        </div>
                                        <div
                                                class="item"
                                                style="
                        width: 70px;
                        text-align: center;
                        position: relative;
                      "
                                        >
                      <span style="font-size: 15px; color: #3f4254"
                      >{{ tab.ProjectWeight }}%</span
                      >
                                        </div>
                                        <div
                                                class="item"
                                                style="
                        width: 70px;
                        text-align: center;
                        position: relative;
                      "
                                        >
                      <span style="font-size: 15px; color: #3f4254">{{
                        tab.ProjectScore
                      }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </a-drawer>
    </div>
</template>

<script>
    import person from "@/components/SelectPersonnel/SelectPersonnel";
    import dynamic from "./OKRDynamic";
    import G6 from "@antv/g6";
    import insertCss from "insert-css";
    import draggable from "vuedraggable";
    import {mapState} from 'vuex';

    export default {
        components: {
            person,
            draggable,
            dynamic
        },
        computed: {
            ...mapState({
                showNav: (state) => state.app.showNav,
            }),
        },
        watch: {
            showNav(newCount, oldCount) {
                if (newCount) {
                    this.containerWidth = window.screen.width - 120 - 40 - 220;
                } else {
                    this.containerWidth = window.screen.width - 120 - 40;
                }
            },
        },
        data() {
            return {
                number: 100,
                number1: 100,
                numberKey: 90,
                changeNum: 80,
                pushNum: 100,
                drawerVisible: false,
                visible: false,
                recruitVisible: false,
                buttonWidth: 45,
                addList: 1,
                keyList: [],
                big: true,
                big1: true,
                bigKey: true,
                changeList: true,
                push: true,
                inputValue: "",
                list: [],
                personObj: {
                    role: 0,
                    positions: 0,
                    rank: 0,
                    nums: 10000, //选的数量
                    department: 1, //0不选部门，1为都选（人 部门都选），2为只选部门，选人
                    contacts: "", //常用联系人 不要常用联系人传空''
                    selectArr: this.relevanceObject, //要传入的数据
                },
                readPerson: [],
                inputObjective: "",
                changeValue: "",
                change: [],
                pushList: [],
                relevanceText: "",
                show: false,
                currentIndex: 0,
                cloumid: this.$route.query.id,
                title: "",
                hidden: true,
                listenModel: "",
                postId: "",
                OKRId: "",
                showIn: "",
                scoreIn: true,

                infoData: [],
                startIndex: "",
                commentList: [],
                bgColor: ["#DCE0E5", "#F3E5EA", "#FFF6E3", "#E2F3F5"],
                textarea: "",
                userInfo: JSON.parse(window.sessionStorage.getItem("userInfo")),
                pageTitle: "",
                detailVisible: false,

                selectDate: "",
                personObj1: {
                    rank: 1, //职级 0为不选，1为选
                    nums: 1, //选的数量
                    department: 1, //0不选部门，1为都选（人 部门都选），2为只选部门，选人
                    contacts: "", //常用联系人 不要常用联系人传空''
                    selectArr: [], //要传入的数据
                },
                personObj2: {
                    rank: 1, //职级 0为不选，1为选
                    nums: 10000, //选的数量
                    department: 1, //0不选部门，1为都选（人 部门都选），2为只选部门，选人
                    contacts: "", //常用联系人 不要常用联系人传空''
                    selectArr: [], //要传入的数据
                },
                personObj3: {
                    role: 0,
                    positions: 0,
                    rank: 0,
                    nums: 10000, //选的数量
                    department: 1, //0不选部门，1为都选（人 部门都选），2为只选部门，选人
                    contacts: "", //常用联系人 不要常用联系人传空''
                    selectArr: [], //要传入的数据
                },
                setId: "",
                alertTitle: "",
                createPer: "",
                startDate: [],
                cutReadPerson: "",
                participate: "",
                schedule: "",
                weight: "",
                total: "",
                setType: "",
                pid: "",
                pageAddText: "",
                dragStartIndex: "",
                triggerDragIndex: "",
                changeDrag: "",
                ListDrag: "",
                selectIndex: "",
                relevanceVisible: false,
                OBindData: [],
                relevanceList: [],
                relevanceSelect: [],
                tatolList: [],
                leaveMsg: "",
                moveMsg: "",

                searchTackText: "",
                centerViewData: "",
                centerViewVisible: false,
                centerViewList: "",
                containerWidth: '',
                startX: 0,
                endX: 0,
                addCommentText: '',
                commitIndex: [],
                detailCommentList: [],
                graph: '',
                starStatus: false,
                stopDown: true,
                okr_info:[],
                isAllowAlignment:false,
                islast:false
            };
        },
        created() {
            this.pageTitle = this.$route.query.title;
            this.containerWidth = window.screen.width - 120 - 40;
            this._infoData();
        },
        methods: {
            _setStarStatus(){
                this.$axios.post(8000080, {ProjectId: this.$route.query.id}, (res) => {
                    if (res.data.code == 1) {
                        this.starStatus = !this.starStatus;
                    }
                });
            },
            _delCommentRow(id,i){
                this.$axios.post(8000057, {CommentId: id}, (res) => {
                    if (res.data.code == 1) {
                        this.detailCommentList.splice(i, 1);
                        this.$message.success("删除成功");
                    }
                });
            },
            _addCommentSave(){
                this.$axios.post(8000045, {TargetId:this.setId,TargetType: 2,Content: this.addCommentText}, (res) => {
                    if (res.data.code == 1) {
                        this.detailCommentList.push({Content: this.addCommentText,TimeText: '刚刚',Id: res.data.data,CreateUserName: this.userInfo.name,Avatar: this.userInfo.Avatar,CreateUserId: this.userInfo.uid});
                        this.addCommentText = '';
                    }
                });
            },
            _containerDown(e) {
                this.startX = e.x;
            },
            _containerMove(e) {
                if (this.startX) {
                    if (this.startX > e.x) {
                        $(".container")[0].scrollLeft = (this.startX - e.x) / 5 + $(".container")[0].scrollLeft;
                    } else {
                        $(".container")[0].scrollLeft = $(".container")[0].srcrollLeft - ((e.x - this.startX) / 5);
                    }
                }
            },
            _containerUp(e) {
                this.startX = 0;
            },
            _refresh() {
                this._infoData();
                if (this.currentIndex == 2) {
                    this.$axios.get(8000065, {OkrId: this.$route.query.id}, (res) => {
                        if (res.data.code == 1) {
                            if(this.centerViewData && this.centerViewData.length!=0){
                                document.getElementById('center-view').innerHTML = '';
                                this.centerViewData = res.data.data;
                                this._centerView();
                            }else{
                                this.centerViewData = res.data.data;
                                this._centerView();
                            }
                        }
                    });
                    this._centerView();
                }
            },
            _centerViewCencel() {
                this.centerViewVisible = false;
            },
            _bingTask(name) {
                this.$axios.get(
                    8000104,
                    {Name: name ? name : this.searchTackText,Type:this.setType},
                    (res) => {
                        if (res.data.code == 1) {
                            this.relevanceList = res.data.data;
                        } else {
                            this.relevanceList = [];
                        }
                        console.log(this.relevanceList);
                    }
                );
            },
            _delOBind(i) {
                this.OBindData.splice(i, 1);
            },
            _centerView() {
                let self = this;
                insertCss(
                    `.g6-component-tooltip {background-color: rgba(0,0,0, 0.65);padding: 10px;box-shadow: rgb(174, 174, 174) 0px 0px 10px;width: fit-content;color: #fff;border-radius = 4px;}`
                );

                // mocked data
                const mockData = self.centerViewData;

                const colors = {
                    B: "#5B8FF9",
                    R: "#F46649",
                    Y: "#EEBC20",
                    G: "#5BD8A6",
                    DI: "#A7A7A7",
                };

                //  组件props
                const props = {
                    data: mockData,
                    config: {
                        //padding: [40, 50],
                        defaultLevel: 3,
                        defaultZoom: 1,
                        modes: {default: ["zoom-canvas", "drag-canvas"]},
                    },
                };
                // console.log(props);
                // return;
                const container = document.getElementById("center-view");
                const width = container.scrollWidth;
                const height = container.scrollHeight || 700;

                // 默认配置
                const defaultConfig = {
                    width,
                    height,
                    modes: {
                        default: ["zoom-canvas", "drag-canvas"],
                    },
                    fitView: false,
                    animate: true,
                    defaultNode: {
                        type: "flow-rect",
                    },
                    defaultEdge: {
                        type: "cubic-horizontal",
                        style: {
                            stroke: "#CED4D9",
                        },
                    },
                    layout: {
                        type: "indented",
                        direction: "LR",
                        dropCap: false,
                        indent: 300,
                        getHeight: () => {
                            return 60;
                        },
                    },
                };

                // 自定义节点、边
                const registerFn = () => {
                    /**
                     * 自定义节点
                     */
                    G6.registerNode(
                        "flow-rect",
                        {
                            shapeType: "flow-rect",
                            draw(cfg, group) {
                                const {name = "", label, collapsed, status, rate, img} = cfg;
                                const grey = "#CED4D9";
                                // 逻辑不应该在这里判断
                                const rectConfig = {
                                    width: 250,
                                    height: 80,
                                    lineWidth: 1,
                                    fontSize: 16,
                                    fill: "#fff",
                                    radius: 4,
                                    stroke: grey,
                                    opacity: 1,
                                };

                                const nodeOrigin = {
                                    x: 0,
                                    y: 0,
                                    // x: -rectConfig.width / 2,
                                    // y: -rectConfig.height / 2,
                                };
                                const textConfig = {
                                    textAlign: "left",
                                    textBaseline: "bottom",
                                };

                                const rect = group.addShape("rect", {
                                    attrs: {
                                        x: nodeOrigin.x,
                                        y: nodeOrigin.y,
                                        ...rectConfig,
                                    },
                                    name: "item-container",
                                });

                                const rectBBox = rect.getBBox();

                                // label title
                                group.addShape("text", {
                                    attrs: {
                                        ...textConfig,
                                        x: 50 + nodeOrigin.x,
                                        y: 35 + nodeOrigin.y,
                                        text: name.length > 28 ? name.substr(0, 28) + "..." : name,
                                        fontSize: 16,
                                        opacity: 0.85,
                                        fill: "#000",
                                        cursor: "pointer",
                                    },
                                    name: "name-shape",

                                });

                                group.addShape("image", {
                                    attrs: {
                                        x: 12 + nodeOrigin.x,
                                        y: 10 + nodeOrigin.y,
                                        width: 30,
                                        height: 30,
                                        img: img,
                                    },
                                    name: "user-img",
                                });

                                // price
                                const price = group.addShape("text", {
                                    attrs: {
                                        ...textConfig,
                                        x: 12 + nodeOrigin.x,
                                        y: rectBBox.maxY - 12,
                                        text: label.length > 13 ? label.substr(0, 13) + "..." : label,
                                        fontSize: 16,
                                        fill: "#000",
                                        opacity: 0.85,
                                    },
                                    // name: "collapse-text",
                                });

                                // bottom line background
                                const bottomBackRect = group.addShape("rect", {
                                    attrs: {
                                        x: nodeOrigin.x,
                                        y: rectBBox.maxY - 4,
                                        width: rectConfig.width,
                                        height: 4,
                                        radius: [0, 0, rectConfig.radius, rectConfig.radius],
                                        fill: "#E0DFE3",
                                    },
                                });

                                // bottom percent
                                const bottomRect = group.addShape("rect", {
                                    attrs: {
                                        x: nodeOrigin.x,
                                        y: rectBBox.maxY - 4,
                                        width: rate * rectBBox.width,
                                        height: 4,
                                        radius: [0, 0, 0, rectConfig.radius],
                                        fill: status,
                                    },
                                });

                                // collapse rect
                                if (cfg.children && cfg.children.length) {
                                    group.addShape("rect", {
                                        attrs: {
                                            x: rectConfig.width - 8,
                                            y: 32,
                                            width: 16,
                                            height: 16,
                                            stroke: "rgba(0, 0, 0, 0.25)",
                                            cursor: "pointer",
                                            fill: "#fff",
                                        },
                                        name: "collapse-back",
                                        modelId: cfg.id,
                                    });

                                    // collpase text
                                    group.addShape("text", {
                                        attrs: {
                                            x: rectConfig.width,
                                            y: 42,
                                            textAlign: "center",
                                            textBaseline: "middle",
                                            text: collapsed ? "+" : "-",
                                            fontSize: 24,
                                            cursor: "pointer",
                                            fill: "rgba(0, 0, 0, 0.25)",
                                        },
                                        name: "collapse-text",
                                        modelId: cfg.id,
                                    });
                                }

                                this.drawLinkPoints(cfg, group);
                                return rect;
                            },
                            update(cfg, item) {
                                const group = item.getContainer();
                                this.updateLinkPoints(cfg, group);
                            },
                            setState(name, value, item) {
                                if (name === 'collapse') {
                                  const group = item.getContainer();
                                  const collapseText = group.find((e) => e.get('name') === 'collapse-text');
                                  if (collapseText) {
                                    if (!value) {
                                      collapseText.attr({
                                        text: '-',
                                      });
                                    } else {
                                      collapseText.attr({
                                        text: '+',
                                      });
                                    }
                                  }
                                }
                            },
                            getAnchorPoints() {
                                return [
                                    [0, 0.5],
                                    [1, 0.5],
                                ];
                            },
                        },
                        "rect"
                    );

                    G6.registerEdge(
                        "flow-cubic",
                        {
                            getControlPoints(cfg) {
                                let controlPoints = cfg.controlPoints; // 指定controlPoints
                                if (!controlPoints || !controlPoints.length) {
                                    const {startPoint, endPoint, sourceNode, targetNode} = cfg;
                                    const {
                                        x: startX,
                                        y: startY,
                                        coefficientX,
                                        coefficientY,
                                    } = sourceNode ? sourceNode.getModel() : startPoint;
                                    const {x: endX, y: endY} = targetNode
                                        ? targetNode.getModel()
                                        : endPoint;
                                    let curveStart = (endX - startX) * coefficientX;
                                    let curveEnd = (endY - startY) * coefficientY;
                                    curveStart = curveStart > 40 ? 40 : curveStart;
                                    curveEnd = curveEnd < -30 ? curveEnd : -30;
                                    controlPoints = [
                                        {x: startPoint.x + curveStart, y: startPoint.y},
                                        {x: endPoint.x + curveEnd, y: endPoint.y},
                                    ];
                                }
                                return controlPoints;
                            },
                            getPath(points) {
                                const path = [];
                                path.push(["M", points[0].x, points[0].y]);
                                path.push([
                                    "C",
                                    points[1].x,
                                    points[1].y,
                                    points[2].x,
                                    points[2].y,
                                    points[3].x,
                                    points[3].y,
                                ]);
                                return path;
                            },
                        },
                        "single-line"
                    );
                };

                registerFn();

                const {data} = props;
                self.graph = null;

                const initGraph = (data) => {
                    if (!data) {
                        return;
                    }
                    const {onInit, config} = props;
                    const tooltip = new G6.Tooltip({
                        // offsetX and offsetY include the padding of the parent container
                        // offsetX 与 offsetY 需要加上父容器的 padding
                        offsetX: 20,
                        offsetY: 30,
                        // the types of items that allow the tooltip show up
                        // 允许出现 tooltip 的 item 类型
                        itemTypes: ["node"],
                        // custom the tooltip's content
                        // 自定义 tooltip 内容
                        getContent: (e) => {
                            const outDiv = document.createElement("div");
                            //outDiv.style.padding = '0px 0px 20px 0px';
                            const nodeName = e.item.getModel().name;
                            let formatedNodeName = "";
                            for (let i = 0; i < nodeName.length; i++) {
                                formatedNodeName = `${formatedNodeName}${nodeName[i]}`;
                                if (i !== 0 && i % 20 === 0)
                                    formatedNodeName = `${formatedNodeName}<br/>`;
                            }
                            outDiv.innerHTML = `${formatedNodeName}`;
                            return outDiv;
                        },
                        shouldBegin: (e) => {
                            if (e.target.get("name") === "name-shape") return true;
                            return false;
                        },
                    });
                    self.graph = new G6.TreeGraph({
                        container: "center-view",
                        ...defaultConfig,
                        ...config,
                        plugins: [tooltip],
                    });
                    if (typeof onInit === "function") {
                        onInit(self.graph);
                    }
                    self.graph.data(data);
                    self.graph.render();
                    self.graph.zoom(config.defaultZoom || 1);

                    const handleCollapse = (e) => {
                        console.log(e.item._cfg.model)
                        const target = e.target;
                        const id = target.get("modelId");
                        const item = self.graph.findById(id);
                        const nodeModel = item.getModel();
                        nodeModel.collapsed = !nodeModel.collapsed;
                        self.graph.layout();
                        self.graph.setItemState(item, "collapse", nodeModel.collapsed);  
                    };
                    self.graph.on("collapse-text:click", (e) => {
                        handleCollapse(e);
                    });
                    self.graph.on("name-shape:click", (e) => {
                        if (self.$route.query.id == e.item._cfg.model.OkrId) {
                            return false;
                        }
                        self.$router.push({
                            path: "/OKRProjectDetails",
                            query: {id: e.item._cfg.model.ProjectId, title: e.item._cfg.model.ProjectName,tabName: e.item._cfg.model.ProjectCreateUser},
                        });
                    });
                    self.graph.on("user-img:click", (e) => {
                        if (self.$route.query.id == e.item._cfg.model.OkrId) {
                            return false;
                        }
                        self.$router.push({
                            path: "/OKRProjectDetails",
                            query: {id: e.item._cfg.model.ProjectId, title: e.item._cfg.model.ProjectName,tabName: e.item._cfg.model.ProjectCreateUser},
                        });
                    });
                    self.graph.on("item-container:click", (e) => {
                        if (self.$route.query.id == e.item._cfg.model.OkrId) {
                            return false;
                        }
                        self.$router.push({
                            path: "/OKRProjectDetails",
                            query: {id: e.item._cfg.model.ProjectId, title: e.item._cfg.model.ProjectName,tabName: e.item._cfg.model.ProjectCreateUser},
                        });
                    });
                };

                initGraph(data);

                if (typeof window !== "undefined")
                    window.onresize = () => {
                        if (!self.graph || self.graph.get("destroyed")) return;
                        if (!container || !container.scrollWidth || !container.scrollHeight)
                            return;
                        self.graph.changeSize(container.scrollWidth, container.scrollHeight);
                    };
            },
            _saveRelevance() {
                this.relevanceSelect.map((res) => {
                    this.OBindData = [this.relevanceList[res]];
                });
                this._relevanceCencel();
            },
            _addRelevance(i) {
                if (this.relevanceSelect.indexOf(i) == -1) {
                    this.$set(this.relevanceSelect, 0, i);
                } else {
                    this.relevanceSelect.splice(this.relevanceSelect.indexOf(i), 1);
                }
            },
            _relevanceCencel() {
                this.relevanceVisible = false;
                this.searchTackText = "";
                this.relevanceList = [];
                this.relevanceSelect = [];
            },
            _relevance() {
                this.relevanceVisible = true;
                this._bingTask(this.userInfo.name);
            },
            _waiDrag() {
                let idList = [];
                this.infoData.map((res) => {
                    idList.push(res.Id);
                });
                this.$axios.post(
                    8000053,
                    {ProjectId: this.$route.query.id, List: JSON.stringify(idList)},
                    (res) => {
                    }
                );
            },
            // 列表外层
            _listDrag() {
                let moveList = [];
                this.keyList.map((res) => {
                    moveList.push(res.Id);
                });
                console.log(moveList);
                this.$axios.post(
                    8000053,
                    {ProjectId: this.$route.query.id, List: JSON.stringify(moveList)},
                    (res) => {
                    }
                );
            },
            _setDom(i) {
                this.showIn = i;
            },
            getProgressColor(i, j) {
                let color = "";
                if (this.keyList[i].Children[j].ProjectStatus == 1) {
                    color = "#3699FF"
                } else if (this.keyList[i].Children[j].ProjectStatus == 2) {
                    color = "#f59336"
                } else if (this.keyList[i].Children[j].ProjectStatus == 3) {
                    color = "#f74e60"
                }
                return color
            },
            recruitEditor() {
                this.drawerVisible = true;
            },
            addResult(i) {

                let tmp = {
                    Avatar: this.userInfo.Avatar,
                    Id: "",
                    Name: "",
                    Pid: "",
                    ProjectScore: 0,
                    ProjectStatus: 0,
                    ProjectWeight: 0,
                    Status: 0,
                };
                let len = this.keyList[i].Children.push(tmp);
                let id = 'int_' + i +'_'+ (len-1);

                //获取焦点
                setTimeout(function(){
                    document.getElementById(id).focus();
                },300);
            },
            // 修改
            submit(e) {
                const {value} = e.target;

                if (this.number > 100) {
                    alert("超过最大值");
                } else if (this.number <= 100) {
                    this.number = String(value);
                }
                this.big = true;
            },
            submit1(e) {
                const {value} = e.target;
                if (this.number1 > 100) {
                    alert("超过最大值");
                } else if (this.number1 <= 100) {
                    this.number1 = String(value);
                }
                this.big1 = true;
            },
            submitKey(e) {
                const {value} = e.target;
                this.numberKey = String(value);
                this.bigKey = true;
            },
            addObject() {
                this.drawerVisible = true;
            },
            cancel() {
                this.drawerVisible = false;
            },
            confirm() {
            },
            // 回复e
            center() {
                if (this.inputValue !== "") {
                    this.list.push({content: this.inputValue});
                } else {
                    alert("请输入内容");
                }
            },
            readChangePerson(value) {
                this.readPerson = value;
            },
            changeSubmit(e) {
                const {value} = e.target;
                this.changeNum = String(value);
                this.changeList = true;
            },
            changeResult() {
                this.change.push(this.change++);
            },
            pushShow() {
                this.push = false;
            },
            pushSubmit(e) {
                const {value} = e.target;
                this.pushNum = String(value);
                this.push = true;
            },
            pushAdd() {
                this.pushList.push(this.pushList++);
            },
            // 列表卡片切换
            swith(index) {
                let self = this;
                if (this.currentIndex == index && index != 2) {
                    return false;
                }
                this.currentIndex = index;
                if (this.currentIndex === 0) {
                    this.show = true;
                    this._infoData();
                } else if (this.currentIndex == 1) {
                    this.show = false;
                    this._infoData();
                } else if (this.currentIndex == 2) {
                    this.$message.loading('加载中...');
                    this.$axios.get(8000105, {OkrId: this.$route.query.id}, (res) => {
                        if (res.data.code == 1) {
                            this.centerViewData = res.data.data;
                            self._centerView();
                        } else {
                            this.centerViewData = [];
                            self._centerView();
                        }
                        this.$message.destroy();
                    });
                }
            },
            _focusBlur(e, i, j) {
                if (this.keyList[i].Children[j].Name == "") {
                    this.$message.error("添加失败,请输入内容");
                    return;
                }
                let parameter = {
                    ProjectId: this.cloumid,
                    Name: this.keyList[i].Children[j].Name,
                    Pid: this.keyList[i].Id,
                    Id: this.keyList[i].Children[j].Id,
                };

                this.$axios.post(8000047, parameter, (res) => {
                    if ((res.data.code = 1)) {
                        // this.keyList[i].Children[j] = res.data.data;
                        this.$set(this.keyList, i, res.data.DataList);
                    }
                });
                this.$message.success("添加成功");
            },
            _saveO(e, i, j) {
                // console.log('this.keyList[i].Children[j]',this.keyList[i]);
                // if (this.keyList[i].Name == "") {
                //   this.$message.error("添加失败,请输入内容");
                //   return;
                // }
                // let parameter = {
                //   ProjectId: this.cloumid,
                //   Name: this.keyList[i].Name,
                //   Pid: this.keyList[i].Id,
                //   Id: this.keyList[i].Children[j].Id,
                // };
                // this.$axios.post(8000047, parameter, (res) => {
                //   if ((res.data.code = 1)) {
                //     // this.keyList[i] = res.data.DataList;
                //     this.$set(this.keyList, i, res.data.DataList);
                //     console.log(this.keyList[i]);
                //     // this.keyList[i].Children[j] = res.data.data；
                //   }
                // });
                // this.$message.success("添加成功");
            },
            _saveKR(e, i, j) {
                // ProjectId 项目id  name:输入框内容 Pid:上级目录id  id:当前id(空) this.keyList[i].Children[j]
                if (this.keyList[i].Children[j].Name == "") {
                    // this.$message.error("添加失败,请输入内容");
                    this.keyList[i].Children.splice(j,1);
                    return;
                }
                let parameter = {
                    ProjectId: this.cloumid,
                    Name: this.keyList[i].Children[j].Name,
                    Pid: this.keyList[i].Id,
                    Id: this.keyList[i].Children[j].Id,
                };

                this.$axios.post(8000047, parameter, (res) => {
                    if ((res.data.code = 1)) {
                        this.keyList[i].Children = res.data.DataList.Children;
                        this.$set(this.keyList[i], i, res.data.DataList);
                    }
                });
                this.$message.success("添加成功");
            },
            _OKRdelete(e, i) {
                this.$axios.post(8000048, {Id: this.infoData[i].Id}, (res) => {
                    if (res.data.code == 1) {
                        this.infoData.splice(i, 1);
                        this.$message.success("删除成功");
                    } else {
                        this.infoData.splice(i, 1);
                        this.$message.success("删除成功");
                    }
                });
            },
            _delete(e, i, j) {
                // console.log("数组", this.keyList[i].Id);
                // return
                let childrenArr = this.keyList[i].Children[j].Id;
                if (childrenArr == "") {
                    this.keyList[i].Children.splice(j, 1);
                    this.$message.success("删除成功");
                } else {
                    this.$axios.post(
                        8000048,
                        {
                            Id:
                                this.currentIndex == 0
                                    ? this.infoData[i].Children[j].Id
                                    : this.keyList[i].Children[j].Id,
                        },
                        (res) => {
                            if (res.data.code == 1) {
                                if (j || j == 0) {
                                    if (this.currentIndex == 0) {
                                        this.infoData[i].Children.splice(j, 1);
                                    } else {
                                        this.$set(this.keyList, i, res.data.DataList);
                                    }
                                } else {
                                    this.keyList.splice(i, 1);
                                }
                                this.$message.success("删除成功");
                            } else {
                                this.$message.error(res.data.message);
                            }
                        }
                    );
                }
            },
            _editorNum(i) {
                this.showIn = i;
            },
            _setProgress(index, tabIndex, type) {
                let Progress = {
                    OkrId: this.keyList[index].Children[tabIndex].Id,
                    Type: type,
                    Value: this.keyList[index].Children[tabIndex].Status,
                }
                this.$axios.post(8000046, Progress, (res) => {
                    if (res.data.code == 1) {
                        this.keyList[index] = res.data.DataList
                        this.showIn = "";
                        this.$message.success("操作成功");
                    } else {
                        this.$message.error(res.data.message)
                    }
                })
            },
            _updata(e, i, j) {
                let ONE = "";
                if (j == 1000000) {
                    ONE = {
                        OkrId: this.keyList[i].Id,
                        Value: this.keyList[i].ProjectWeight,
                    };
                } else {
                    ONE = {
                        OkrId: this.keyList[i].Children[j].Id,
                        Value: this.keyList[i].Children[j].ProjectWeight,
                    };
                }
                console.log(this.keyList[i]);
                this.$axios.post(8000050, ONE, (res) => {
                    if (res.data.code == 1) {
                        this.keyList[i] = res.data.DataList;
                        this.showIn = "";
                        this.$message.success("操作成功");
                        // console.log('列表保存', res.data)
                    } else {
                        this.$message.error(res.data.message);
                    }
                });
            },
            _scoreData(e, i, j) {
                // console.log(this.keyList[i].Children[j])
                let score = {
                    OkrId: this.keyList[i].Children[j].Id,
                    Value: this.keyList[i].Children[j].ProjectScore,
                };
                this.$axios.post(8000051, score, (res) => {
                    if (res.data.code == 1) {
                        this.keyList[i] = res.data.DataList;
                        // this.tatolList = res.data.data;
                        this.showIn = "";
                        this.$message.success("操作成功");
                    } else {
                        this.$message.error(res.data.message);
                    }
                });
            },

            _setData(id, num, pid, i,commitIndex,islast = false) {
                this.islast = islast;
                this.setType = num;
                this.setId = id;
                this.commitIndex = commitIndex;
                this.selectIndex = num == 1 ? i : "";
                this.pid = pid ? pid : "";
                this.$message.loading("加载中...");
                this.$axios.get(8000049, {OkrId: id}, (res) => {
                    this.$message.destroy();
                    if (res.data.code == 1) {
                        let data = res.data.data;
                        this.alertTitle = data.Info.Name;
                        this.selectDate = [data.Info.StartDate, data.Info.PlanEndDate];
                        if (data.Info.StartDate && data.Info.StartDate != "null") {
                            this.startDate = [
                                this.__moment__(data.Info.StartDate),
                                this.__moment__(data.Info.PlanEndDate),
                            ];
                        } else {
                            this.startDate = [];
                        }
                        this.createPer = data.Info.CreateUserName;
                        this.cutReadPerson =
                            data.Responsible.length != 0 ? [data.Responsible] : "";
                        this.personObj1.selectArr = this.cutReadPerson || [];
                        this.participate = data.Participate;
                        this.personObj2.selectArr = this.participate || [];
                        this.schedule = data.Info.Status;
                        this.weight = data.Info.ProjectWeight;
                        this.total = data.Info.ProjectScore;
                        this.detailVisible = true;
                        this.OBindData = data.OBindData;
                        this.detailCommentList = data.Comment;
                        this.okr_info = data;

                        //判断是否可以操作对齐 O的创建人或者主要负责人
                        this.isAllowAlignment = data.isAllowAlignment;
                    }
                });
            },
            _customDate(date) {
                if (date.length > 0) {
                        this.selectDate = [
                        this.__moment__(date[0]).format("YYYY-MM-DD"),
                        this.__moment__(date[1]).format("YYYY-MM-DD"),
                    ];
                } else {
                    this.selectDate = ['',''];
                }
            },
            _sub() {
                let OBindData = [];
                this.OBindData.map((tab) => {
                    OBindData.push(tab.Id);
                });
                let item = {
                    Name: this.alertTitle,
                    StartDate: this.selectDate[0],
                    EndDate: this.selectDate[1],
                    OperationType: this.pid ? 2 : 1,
                    ProjectId: this.$route.query.id,
                    Id: this.setId,
                    Pid: this.pid,
                    Directors: JSON.stringify(this.cutReadPerson),
                    Participants: JSON.stringify(this.participate),
                    OKRSpeed: this.schedule,
                    OKRWeight: this.weight,
                    OKRScore: this.total,
                    ToOkrIdList: OBindData.length > 0 ? JSON.stringify(OBindData) : "",
                };
                this.$axios.post(8000043, item, (res) => {
                    if (res.data.code == 1) {
                        console.log('滴答', res.data.data);
                        this._infoData();
                        this._cancelData();
                    } else {
                        this.$message.error(res.data.message);
                    }
                });
            },
            _delPerson(i) {
                this.participate.splice(i, 1);
            },
            participatePerson(value) {
                this.participate = value;
            },
            cutReadChangePerson(value) {
                this.cutReadPerson = value;
            },
            _cancelData() {
                this.alertTitle = "";
                this.createPer = this.userInfo.name;
                this.startDate = [];
                this.selectDate = "";
                this.cutReadPerson = "";
                this.participate = "";
                this.setId = "";
                this.setType = "";
                this.pid = "";
                this.detailVisible = false;
                this.personObj1.selectArr = [];
                this.personObj2.selectArr = [];
                this.relevanceList = [];
                this.relevanceSelect = [];
                this.selectIndex = "";
                this.OBindData = [];
                this.detailCommentList = [];
                this.stopDown = true;
            },
            _addRow(e, index) {
                console.log("target", e.target.value);
                if (e.target.value) {
                    let item = {
                        Avatar: this.userInfo.Avatar,
                        Name: e.target.value,
                        ProjectStatus: 1,
                        Status: 0,
                    };
                    this.$axios.post(
                        8000047,
                        {
                            ProjectId: this.$route.query.id,
                            Name: e.target.value,
                            Pid: index || index == 0 ? this.infoData[index].Id : "",
                        },
                        (res) => {
                            if (res.data.code == 1) {
                                res.data.data.Avatar = this.userInfo.Avatar;
                                res.data.data.Children = [];
                                this.pageAddText = "";
                                e.target.value = "";
                                if (index || index == 0) {
                                    this.$set(this.keyList, index, res.data.DataList);
                                    this.$set(this.infoData, index, res.data.DataList);
                                } else {
                                    if (this.currentIndex == 0) {
                                        this.infoData.push(res.data.data);
                                    } else {
                                        this.keyList.push(res.data.data);
                                    }
                                }
                            } else {
                                this.$message.error(res.data.message);
                            }
                        }
                    );
                }
            },
            _setMsg(index, tabIndex, type, val) {
                let item = {
                    OkrId:
                        tabIndex || tabIndex == 0
                            ? this.currentIndex == 0
                            ? this.infoData[index].Children[tabIndex].Id
                            : this.keyList[index].Children[tabIndex].Id
                            : this.keyList[index].Id,
                    Type: type,
                    Value:
                        type == "Status"
                            ? val
                            : document.getElementById("status-input-" + index + "-" + tabIndex)
                                .value,
                };
                this.$axios.post(8000046, item, (res) => {
                    if (res.data.code == 1) {
                        this.$message.success(res.data.message);
                        if (type == "Status") {
                            if (this.currentIndex == 0) {
                                this.infoData[index].Children[tabIndex].ProjectStatus = val;
                            } else {
                                if (tabIndex || tabIndex == 0) {
                                    this.keyList[index].Children[tabIndex].ProjectStatus = val;
                                } else {
                                    this.keyList[index].ProjectStatus = val;
                                }
                            }
                        } else {
                            this.$set(this.keyList, index, res.data.DataList);
                        }
                    } else {
                        this.$message.error(res.data.message);
                    }
                });
            },
            // 鼠标移入
            _overMsg(index, tabIndex, type, val) {
                let overMsg = this.$refs.status[index].value;
                this.moveMsg = overMsg;
            },
            // 鼠标移出,
            _leaveMsg(index, tabIndex, type, val) {
                let tgp = this.$refs.status[index].value;
                this.leaveMsg = tgp;
                if (this.leaveMsg == this.moveMsg) {
                    return;
                } else if ((this.leaveMsg = tgp)) {
                    let item = {
                        OkrId:
                            tabIndex || tabIndex == 0
                                ? this.currentIndex == 0
                                ? this.infoData[index].Children[tabIndex].Id
                                : this.keyList[index].Children[tabIndex].Id
                                : this.keyList[index].Id,
                        Type: type,
                        Value:
                            type == "Status"
                                ? val
                                : document.getElementById(
                                "status-input-" + index + "-" + tabIndex
                                ).value,
                    };
                    this.$axios.post(8000046, item, (res) => {
                        if (res.data.code == 1) {
                            this.$message.success(res.data.message);
                            if (type == "Status") {
                                if (this.currentIndex == 0) {
                                    this.infoData[index].Children[tabIndex].ProjectStatus = val;
                                } else {
                                    if (tabIndex || tabIndex == 0) {
                                        this.keyList[index].Children[tabIndex].ProjectStatus = val;
                                    } else {
                                        this.keyList[index].ProjectStatus = val;
                                    }
                                }
                            } else {
                                this.$set(this.keyList, index, res.data.DataList);
                            }
                        } else {
                            this.$message.error(res.data.message);
                        }
                    });
                }
            },
            _infoData(index) {
                this.$message.loading("加载中...");
                this.$axios.get(8000040, {ProjectId: this.$route.query.id}, (res) => {
                    if (res.data.code == 1) {
                        this.$message.destroy();
                        if (res.data.data.length == 0) {
                            this.infoData = [];
                            this.keyList = [];
                        } else {
                            this.infoData = res.data.data;
                            this.keyList = res.data.data;
                            this.postId = res.data.data.Id;
                        }
                        this.$set(this.keyList, index, res.data.DataList);
                    }
                    this.starStatus = res.data.IsFollow == 1 ? true : false;
                });

                this.$axios.get(8000044, {ProjectId: this.$route.query.id}, (res) => {
                    if (res.data.code == 1) {
                        this.commentList = res.data.data;
                        this.$set(this.commentList, res.data.data)
                    }
                });
            },
            kaConfirm(i) {
                let self = this;
                this.$axios.post(8000048, {Id: self.infoData[i].Id}, (res) => {
                    if (res.data.code == 1) {
                        self.infoData.splice(i, 1);
                        self.$message.success("删除成功");
                    } else {
                        self.$message.error(res.data.message);
                    }
                });
            },
            _save() {
                if (this.textarea) {
                    this.$axios.post(
                        8000045,
                        {
                            TargetType: 1,
                            TargetId: this.$route.query.id,
                            Content: this.textarea,
                        },
                        (res) => {
                            if (res.data.code == 1) {
                                this.$set(this.commentList)
                                // console.log(res.data);
                                this.commentList.push({
                                    Id: res.data.data,
                                    Content: this.textarea,
                                    CreateUserName: this.userInfo.name,
                                    TimeText: "刚刚",
                                    Avatar: this.userInfo.Avatar,
                                });
                                this.textarea = "";
                            }
                        }
                    );
                }
            },
            _startDrag(i) {
                console.log(123456)
                this.stopDown = false;
                this.startX = 0;
                this.dragStartIndex = i;
            },
            _endDrag(){
                this.stopDown = true;
            },
            // _setData(id, num, pid, i) {
            //   this.setType = num;
            //   this.setId = id;
            //   this.selectIndex = num == 1 ? i : "";
            //   this.pid = pid ? pid : "";
            //   this.$message.loading("加载中...");
            //   this.$axios.get(8000049, { OkrId: id }, (res) => {
            //     this.$message.destroy();
            //     if (res.data.code == 1) {
            //       console.log('55',res.data.data);
            //       let data = res.data.data;
            //       this.alertTitle = data.Info.Name;
            //       this.selectDate = [data.Info.StartDate, data.Info.PlanEndDate];
            //       if (data.Info.StartDate && data.Info.StartDate != "null") {
            //         this.startDate = [
            //           this.__moment__(data.Info.StartDate),
            //           this.__moment__(data.Info.PlanEndDate),
            //         ];
            //       } else {
            //         this.startDate = [];
            //       }
            //       this.createPer = data.Info.CreateUserName;
            //       this.cutReadPerson =
            //         data.Responsible.length != 0 ? [data.Responsible] : "";
            //       this.personObj1.selectArr = this.cutReadPerson || [];
            //       this.participate = data.Participate;
            //       this.personObj2.selectArr = this.participate || [];
            //       this.schedule = data.Info.Status;
            //       this.weight = data.Info.ProjectWeight;
            //       this.total = data.Info.ProjectScore;
            //       this.detailVisible = true;
            //       this.OBindData = data.OBindData;
            //       console.log(data);
            //     }
            //   });
            // },
            // 列表内层
            _start(i) {

                this.triggerDragIndex = i;
            },
            _addDrag(e) {

                this._draw(e.oldDraggableIndex, e.newDraggableIndex);
            },
            _addListDrag(e) {
                this._drawList(e.oldIndex, e.newIndex);
            },
            _changeDrag(e) {
                this.stopDown = true;
                if (e.added) {
                    this.changeDrag = e.added.element;
                }
                if (e.moved) {
                    this.changeDrag = e.moved.element;
                }
            },
            // 列表内层
            _liDrag(evt) {
                if (evt.added) {
                    this.ListDrag = evt.added.element;
                }
                if (evt.moved) {
                    this.ListDrag = evt.moved.element;
                }
            },
            _draw(oldIdx, newIdx) {
                let toList = [];
                let item = "";
                let toId = "";

                this.infoData.map((res) => {
                    res.Children.map((tag) => {
                        if (tag.Id == this.changeDrag.Id) {
                            toId = res.Id;
                            item = res.Children;
                        }
                    });
                });
                item.map((res) => {
                    toList.push(res.Id);
                });
                this.$axios.post(
                    8000052,
                    {
                        formId: this.infoData[this.dragStartIndex].Id,
                        toId: toId,
                        toList: JSON.stringify(toList),
                        targetId: this.changeDrag.Id,
                    },
                    (res) => {
                        if (res.data.code == 1) {
                        }
                    }
                );
            },
            _sortDrag() {
                this.stopDown = true;
                let toList = [];
                this.infoData[this.dragStartIndex].Children.map((res) => {
                    toList.push(res.Id);
                });
                this.$axios.post(
                    8000052,
                    {
                        formId: this.infoData[this.dragStartIndex].Id,
                        toId: this.infoData[this.dragStartIndex].Id,
                        toList: JSON.stringify(toList),
                        targetId: this.changeDrag.Id,
                    },
                    (res) => {
                        if (res.data.code == 1) {
                        }
                    }
                );
            },
            // 列表内层
            _drawList(oldIdx, newIdx) {
                let traggerList = [];
                let itemList = "";
                let DragId = "";
                this.keyList.map((res) => {
                    res.Children.map((tag) => {
                        if (tag.Id == this.ListDrag.Id) {
                            DragId = res.Id;
                            itemList = res.Children;
                        }
                    });
                });
                itemList.map((res) => {
                    traggerList.push(res.Id);
                });
                this.$axios.post(
                    8000052,
                    {
                        formId: this.keyList[this.triggerDragIndex].Id,
                        toId: DragId,
                        toList: JSON.stringify(traggerList),
                        targetId: this.ListDrag.Id,
                    },
                    (res) => {
                        if (res.data.code == 1) {
                        }
                    }
                );
            },
            _sort() {
                let traggerList = [];
                this.keyList[this.triggerDragIndex].Children.map((res) => {
                    traggerList.push(res.Id);
                });
                // return;
                this.$axios.post(
                    8000052,
                    {
                        formId: this.keyList[this.triggerDragIndex].Id,
                        toId: this.keyList[this.triggerDragIndex].Id,
                        toList: JSON.stringify(traggerList),
                        targetId: this.ListDrag.Id,
                    },
                    (res) => {
                        if (res.data.code == 1) {
                        }
                    }
                );
            },
            confirmEVA(e, i) {
                this.$axios.post(
                    8000057,
                    {CommentId: this.commentList[i].Id},
                    (res) => {
                        if (res.data.code == 1) {
                            this.commentList.splice(i, 1);
                            this.$message.success("删除成功");
                        }
                    }
                );
            },

            _onStart(){
                this.stopDown = false;
                this.startX = 0;
                console.log(11111)
            },
            _onChange(){
                this.stopDown = true;
                console.log(2222)
            }
        },
    };
</script>

<style lang="less" scoped>
    .model-comment{
        padding-bottom: 50px;
        background-color: #F5F6F7;
        box-sizing: border-box;
        .model-comment-content{
            padding: 10px 20px;
            .add-comment-model{
                width: 100%;
                padding: 10px;
                margin: 10px auto;
                background-color: #fff;
                border-radius: 6px;
                textarea{
                    resize: none;
                    width: 100%;
                    height: 80px;
                    border: none;
                    font-size: 13px;
                    color: #666;
                    outline: none;
                }
            }
            .list-item{
                margin-bottom: 15px;
                .comment-avatar{
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    margin-right: 5px;
                    vertical-align: middle;
                }
                .comment-name{
                    font-size: 14px;
                    color: #666;
                }
                .comment-time{
                    margin-left: 5px;
                    font-size: 12px;
                    color: #999;
                }
                .comment-text{
                    padding-left: 35px;
                    margin-top: 5px;
                    font-size: 14px;
                    color: #666;
                }
            }
        }
        .model-comment-title{
            font-size: 17px;
            color: #666;
            padding: 10px 20px;
            border-bottom: 1px solid #E5E6E9;
        }
    }
    .commit-list-item{
        margin-bottom: 15px;
        &:last-child{
            margin-bottom: 0;
        }
        .item-content{
            margin-bottom: 2px;
            color: #1D1D1D;
            font-size: 15px;
        }
        .operation-msg{
            display: flex;
            color: #7E8299;
            font-size: 14px;
            img{
                width: 25px;
                height: 25px;
                margin-right: 4px;
                border-radius: 50%;
                display: inline-block;
                vertical-align: middle;
            }
        }
    }
    .tags{
        color: #1890ff;
        width: 30px;
        display: inline-block;
        padding-left: 9px;
        border-radius: 20px;
        font-family: cursive;
        
    }
    @import "./OKRProjectDetails.less";
</style>
