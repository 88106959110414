<template>
    <div class="OKRDynamic">
        <div class="header">
            <a-button :type="tab==1 ? 'primary' : ''" @click="_cutTab(1)">本周</a-button>
            <a-button :type="tab==2 ? 'primary' : ''" @click="_cutTab(2)">上周</a-button>
            <a-button :type="tab==3 ? 'primary' : ''" @click="_cutTab(3)">上上周</a-button>
            <a-range-picker style="margin-right: 10px;width:200px" @change="_getDate"/>
            <a-button type="primary" @click="_info()">查询</a-button>
        </div>

        <div class="container">
            <div v-for="(item,index) in infoData" style="margin-bottom: 15px;">
                <div @click="_showContent(index)" class="project-title flex">
                    <div>
                        <span class="line"></span>
                        <img class="avatar" :src="item.Avatar" alt="">
                        <div class="project-msg">
                            <div class="project-name">{{item.Title}}</div>
                            <div class="project-person">{{item.SubTitle}}</div>
                        </div>
                    </div>
                    <div class="pack-up">{{showIndex.indexOf(index)==-1?'收起':'展开'}}</div>
                </div>

                <div class="project-content flex" style="align-items: inherit" v-show="showIndex.indexOf(index)==-1">
                    <div class="project-dynamic">
                        <div class="title">评论动态</div>
                        <div class="content">
                            <div class="list-item" v-for="(tab,tabIndex) in item.Comment">
                                <div class="item-content">{{tab.Content}}</div>
                                <div class="operation-msg">
                                    <img :src="tab.Avatar" alt="">
                                    <div>{{tab.SubTitle}}</div>
                                </div>
                            </div>
                            <div v-if="item.Comment.length==0" style="color: #ddd;font-size: 18px;padding: 20px 0;text-align: center">Ta什么都没有写</div>
                        </div>
                    </div>
                    <div class="project-log">
                        <div class="title">进度动态</div>
                        <div class="content">
                            <div class="list-item" v-for="(tab,tabIndex) in item.Logs">
                                <div class="item-content">{{tab.Content}}</div>
                                <div class="operation-msg">
                                    <img :src="tab.Avatar" alt="">
                                    <div>{{tab.SubTitle}}</div>
                                </div>
                            </div>
                            <div v-if="item.Logs.length==0" style="color: #ddd;font-size: 18px;padding: 20px 0;text-align: center">Ta什么都没有写</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "OKRDynamic",
        data(){
            return {
                tab: 1,
                userInfo: JSON.parse(window.sessionStorage.getItem('userInfo')),
                infoData: '',
                showIndex: [],
                startDate: '',
                endDate: ''
            }
        },
        created(){
            if (this.$parent.currentIndex == 3) {
                this._info();
            }
        },
        methods: {
            _getDate(date){
                this.tab = 0;
                this.startDate = this.__moment__(date[0]).format('YYYY-MM-DD');
                this.endDate = this.__moment__(date[1]).format('YYYY-MM-DD');
            },
            _cutTab(i){
                this.tab = i;
                this._info();
            },
            _showContent(i){
                if(this.showIndex.indexOf(i)==-1){
                    this.showIndex.push(i);
                }else{
                    this.showIndex.splice(this.showIndex.indexOf(i),1);
                }
            },
            _info(){
                this.$message.loading('加载中...');
                this.$axios.get(8000081,{ProjectId: this.$route.query.id,WeekId:this.tab,StartDate: this.startDate,EndDate: this.endDate},res=>{
                    this.$message.destroy();
                    this.infoData = res.data.data;
                })
            },
        }
    }
</script>

<style scoped lang="less">
    .OKRDynamic{
        .header{
            margin-top: 5px;
            button{
                margin-right: 10px;
            }
        }
        .container{
            padding: 20px 0;
            .project-content{
                margin-top: 20px;
                .project-dynamic{
                    flex: 1;
                    border-radius: 6px;
                    background-color: #fff;
                }
                .project-log{
                    margin-left: 15px;
                    width: 400px;
                    border-radius: 6px;
                    background-color: #fff;
                }
                .content{
                    padding: 15px 20px;
                    .list-item{
                        margin-bottom: 15px;
                        &:last-child{
                            margin-bottom: 0;
                        }
                        .item-content{
                            margin-bottom: 2px;
                            color: #1D1D1D;
                            font-size: 15px;
                        }
                        .operation-msg{
                            display: flex;
                            color: #7E8299;
                            font-size: 14px;
                            img{
                                width: 25px;
                                height: 25px;
                                margin-right: 4px;
                                border-radius: 50%;
                                display: inline-block;
                                vertical-align: middle;
                            }
                        }
                    }
                }
                .title{
                    padding: 8px 20px;
                    color: #1D1D1D;
                    font-size: 16px;
                    border-bottom: 1px solid #F3F3F9;
                }
            }
            .project-title{
                position: relative;
                padding: 10px 15px;
                background-color: #fff;
                border-radius: 6px;
                .line{
                    display: inline-block;
                    width: 4px;
                    height: 40px;
                    background-color: #E1F0FF;
                    vertical-align: middle;
                    border-radius: 1px;
                }
                .avatar{
                    width: 42px;
                    height: 42px;
                    border-radius: 50%;
                    margin: 0 10px;
                    vertical-align: middle;
                }
                .project-msg{
                    display: inline-block;
                    vertical-align: middle;
                    .project-name{
                        color: #3F4254;
                        font-size: 16px;
                    }
                    .project-person{
                        color: #B5B6C7;
                        font-size: 14px;
                    }
                }
                .pack-up{
                    color: #7E8299;
                    font-size: 16px;
                    cursor: pointer;
                }
            }
        }
    }
</style>
